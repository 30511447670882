// const { default: Swiper } = require("swiper");

document.addEventListener("DOMContentLoaded", function() {
    const breakpoint = window.matchMedia( '(min-width:1025px)' );
    let swiperPromo;
    let swiperMainPrograms;
    let swiperBrands;
    let swiperProgramBrands;
    const breakpointChecker = function() {
        if ( breakpoint.matches === true ) {
            if ( swiperPromo !== undefined ) swiperPromo.destroy( true, true );
            if ( swiperMainPrograms !== undefined ) swiperMainPrograms.destroy( true, true );
            if ( swiperBrands !== undefined ) swiperBrands.destroy( true, true );
            if ( swiperProgramBrands !== undefined ) swiperProgramBrands.destroy( true, true );
            return;
        } else if ( breakpoint.matches === false ) {
            return enableSwiper();
        }

    };
    const enableSwiper = function() {
        if(document.querySelector('.main-promo__partners')){
            swiperPromo = new Swiper ('.main-promo__partners', {
                slidesPerView: 2,
                spaceBetween: 20,
                loop: true,
                pagination: {
                    el: '.main-promo__partners-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    700: {
                        slidesPerView: 3
                    },
                }
            });
        }
        if(document.querySelector('.main-programs__slider')){
            swiperMainPrograms = new Swiper ('.main-programs__slider', {
                slidesPerView: 1,
                loop: false,
                grid: {
                    fill:'row',
                    rows: 3,
                },
                pagination: {
                    el: '.main-programs__slider-pagination  ',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    700: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        loop: false,
                        grid: {
                            fill:'column',
                            rows: 1,
                        },
                    },
                },
                on: {
                    destroy: function () {
                        initAnimation();
                    },
                },
            });
        }
        if(document.querySelector('.brands-list')){
            swiperBrands = new Swiper ('.brands-list', {
                direction: 'horizontal',
                slidesPerView: 1,
                spaceBetween: 20,
                grid: {
                    fill:'row',
                    rows: 3,
                },
                loop: false,
                navigation: {
                    nextEl: ".brands-list__next",
                    prevEl: ".brands-list__prev",
                },
                breakpoints: {
                    481: {
                        slidesPerView: 2,
                        grid: {
                            fill:'row',
                            rows: 2,
                        },
                    },
                    700: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                        grid: {
                            fill:'column',
                            rows: 1,
                        },
                    },
                }
            });
        }
        if(document.querySelector('.program-brands-list')){
            swiperProgramBrands = new Swiper ('.program-brands-list', {
                slidesPerView: 1,
                spaceBetween: 20,
                loop: false,
                navigation: {
                    nextEl: ".program-brands-list__next",
                    prevEl: ".program-brands-list__prev",
                },
                breakpoints: {
                    481: {
                        slidesPerView: 2,
                    },
                    700: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }
            });
        }
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();

    var swiper = new Swiper(".reviews-list", {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: ".reviews-list__action-btn.next",
          prevEl: ".reviews-list__action-btn.prev",
        },
        breakpoints: {
            700: {
                slidesPerView: 2,
            },
        }
      });

    var mySwiper = new Swiper('.about-slider',{
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 20,
      grid: {
        fill:'row',
        rows: 3,
      },
      // loop: true,
      navigation: {
        nextEl: ".about-slider__next",
        prevEl: ".about-slider__prev",
      },
      breakpoints: {
        481: {
          slidesPerView: 2,
          spaceBetween: 20,
          grid: {
            fill:'column',
            rows: 1,
          },
        },
        700: {
          slidesPerView: 3,
          spaceBetween: 20,
          grid: {
            fill:'column',
            rows: 1,
          },
        },
        960: {
          slidesPerView: 5,
          spaceBetween: 20,
          grid: {
            fill:'column',
            rows: 1,
          },
        }
      }
    })
});