import SlimSelect from 'slim-select';
import IMask from 'imask';
import bulmaCalendar from '../../node_modules/bulma-calendar/dist/js/bulma-calendar.min.js';

const selects = document.querySelectorAll('select');
selects.forEach(select => {
    new SlimSelect({
        select: select,
        placeholder: false,
        showSearch: false,
    })
})

const phones = document.querySelectorAll('input[type="tel"]');
phones.forEach(phone => {
    IMask(phone,  {
        mask: '+{7}(000)000-00-00',
        prepare: (appended, masked) => {
            if (appended === '8' && masked.value === '') {
                return '';
            }
            return appended;
        },
    });
});

phones.forEach(function(item){
    item.addEventListener('input', function(){
      let  inputVal = this.value;
        if(inputVal.charAt(4) === '9') {
        } else {
            inputVal =  inputVal.substring(4);
        }
    })
})

const calendars = bulmaCalendar.attach('[type="date"]', {
    showHeader: false,
    showFooter: false,
    showButtons: false,
    color: 'link',
    dateFormat: 'dd.MM.yyyy',
    lang: 'ru',
});

const addMessageError = (field) => {
    let parent = field.closest('.checkbox-wrap')? field.closest('.checkbox-wrap') : field.parentElement;
    field.addEventListener('invalid', (e) => {
        if (!field.validity.valid) {
            e.preventDefault();
            field.setCustomValidity('');
            if (!parent.classList.contains('form__field--error')) {
                parent.classList.add('form__field--error');
                let errorMessage = document.createElement('div');
                errorMessage.classList.add('form__error');
                errorMessage.innerHTML = field.validationMessage;
                parent.append(errorMessage);
            } else {
                parent.querySelector('.form__error').innerText = field.validationMessage;
            }
        }
    });
}

const removeMessageError = (field) => {
    const parent = field.closest('.checkbox-wrap')? field.closest('.checkbox-wrap') : field.parentElement;
    if (parent.classList.contains('form__field--error')) {
        parent.classList.remove('form__field--error');
        let errorMessage = parent.querySelector('.form__error');
        errorMessage.remove();
    }
}

document.addEventListener("DOMContentLoaded", function () {
    const elements = document.querySelectorAll('input, textarea, select');
    elements.forEach(function(element) {
        addMessageError(element);

        element.addEventListener((element.tagName === 'SELECT' ? 'change' : 'input'), function () {
            if (element.validity.valid) {
                removeMessageError(element);
            }
            element.setCustomValidity('');
            element.reportValidity();
        });
    });

    const forms = document.querySelectorAll("form");
    forms.forEach(function (form) {
        const submit = form.querySelector("[type='submit']");
        if (submit) {
            submit.addEventListener("click", function(){
                const formFields = form.querySelectorAll("input,select,textarea")
                for (const field of formFields) {
                    if(field.validity && !field.validity.valid) {
                        field.scrollIntoView({block: "start", behavior: "smooth"});
                        break;
                    }
                }
            });
        }
    });
});

const resetForm = function (form) {
    const errors = form.querySelectorAll('.form__field--error');
    const errorMessages = form.querySelectorAll('.form__error');
    errors.forEach(error => {
        error.classList.remove('form__field--error');
    });
    errorMessages.forEach(message => {
        message.remove();
    });
    form.querySelector('select').selectedIndex = 0;
    form.reset();
}
