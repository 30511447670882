/* Libs */
import './libs/scrollreveal.min.js'
import './libs/swiper-bundle.min.js'


import "core-js/stable";
import "regenerator-runtime/runtime";


import './form'

/* ---------- */
const video = document.querySelector('.main-promo__video');
const promoCard = document.querySelector('.main-promo__card');
const videoBox = document.querySelector('.main-promo');
if (video) {
    if(window.innerWidth >= 1200){
    video.addEventListener('canplaythrough', function () {
            promoCard.classList.add('animate')
            videoBox.classList.add('animate')
    })} else {
        promoCard.classList.add('animate');
    }
}

/* Animation */
window.initAnimation = function (){
    const animationList = document.querySelectorAll('.js-animation');

    animationList.forEach(item => {
        const settings = {
            origin: item.dataset.origin ? item.dataset.origin : 'bottom',
            duration: 1500,
            useDelay: 'always',
            viewFactor: item.dataset.viewfactor,
            distance: item.dataset.distance ? item.dataset.distance : '0',
            delay: item.dataset.delay ? item.dataset.delay : 0,
        }

        ScrollReveal().reveal(item, settings)
    })
}

window.onload = function() {
    initAnimation();
};

/* ---------- */

window.initAccordion = function (){
    const body = document.querySelector("body");

    body.addEventListener("click", function(e) {
        let target = e.target;
        if(target && target.closest('.js-accordion')){
            const acc = target.closest('.js-accordion');
            acc.classList.toggle("active");
            let panel = acc.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        }
    });
}
initAccordion();

/* Slider */
import './slider';
const body = document.querySelector('body');
const getScrollbarWidth = function () {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

}
const setModals = function() {
    document.addEventListener('DOMContentLoaded',function(e){
    var modalButtons = document.querySelectorAll('.js-open-modal'),
       overlay      = document.querySelectorAll('.js-overlay-modal'),
       closeButtons = document.querySelectorAll('.js-modal-close');
   modalButtons.forEach(function(item){
      item.addEventListener('click', function(e) {
         e.preventDefault();
         var modalId = this.getAttribute('data-modal'),
         modalElem = document.querySelector('.modal[data-modal="' + modalId + '"]');
         modalElem.classList.add('active');
          const scrollBarWidth = getScrollbarWidth();
          body.style.paddingRight = '15px';
          body.classList.add('hide-scroll');

          if(document.querySelector('#feedback')){
              initUploader('.uploader', '#feedback');
          }
          if(document.querySelector('#requireForm')){
              initUploader('.uploader', '#requireForm');
          }
      });
   });
   closeButtons.forEach(function(item){
      item.addEventListener('click', function(e) {
          closeModal();
      });
   });
    overlay.forEach(function(item){
        item.addEventListener('click', function() {
            closeModal();
            })
        });
    })
    let closeModal = function(){
        document.querySelector('.modal.active').classList.remove('active');
        body.style.paddingRight = null;
        body.classList.remove('hide-scroll')
    }
}
setModals()

const setBurger = function(){
    let burgerBtn = document.querySelector('.header__burger');
    let navMenu = document.querySelector('.header-menu');
    burgerBtn.addEventListener('click', function(){
        burgerBtn.classList.toggle('active');
        navMenu.classList.toggle('active')
    })
}
setBurger()

const setMenuAccordeon = function(){
    let menuBtn = document.querySelectorAll('.header-menu__dropdown');
    menuBtn.forEach(function(item){
        item.addEventListener('click', function(){
            this.classList.toggle('active')
            this.querySelector('.header-menu__dropdown-list').classList.toggle('active')
        })
    })
}
setMenuAccordeon()

document.addEventListener('DOMContentLoaded',function(){
    const setBlogPanel = function(){
        let panel = document.querySelector('.blog-asside__panel');

        if(panel) {
            let list = document.querySelector('.blog-asside__categories')
            let listClose = document.querySelector('.blog-asside__categories-close')
            panel.addEventListener('click', function(){
                if(!list.classList.contains('active')){
                    list.classList.add('active')
                    list.style.height = 'auto';
                    let height = list.clientHeight + 'px';
                    list.style.height = '0';
                    setTimeout(() => {
                        list.style.height = height;
                    }, 0)
        
                }
                 else {
                     list.style.height = '0'
                 }
        
            })
            listClose.addEventListener('click', function(){
                list.classList.remove('active')
            })
        }
    }
    setBlogPanel()
})


const setTabs = function(tabItems, tabContents, tabTitles){
    let tabs = document.querySelectorAll(tabItems);
    let tabsContent = document.querySelectorAll(tabContents);
    let titles = document.querySelectorAll(tabTitles);
    for(let i = 0; i< tabs.length; i++) {
        tabs[i].addEventListener('click', function(){
            tabs.forEach(function(item){
                item.classList.remove('active')
            })
            this.classList.add('active');
            tabsContent.forEach(function(item){
                item.classList.remove('active')
            })
            tabsContent[i].classList.add('active')
            if(titles){
                titles.forEach(function(item){
                    item.classList.remove('active')
                })
                titles[i].classList.add('active')
            }
        })
    }
}
setTabs('.services-tabs__item', '.services-item');

const setNumbersOfCars = function() {
    let btnPlus = document.querySelectorAll('.select-custom__btn.plus')
    let btnMinus = document.querySelectorAll('.select-custom__btn.minus')
    let field = document.querySelectorAll('.calculator-input__wrap');
    let input = document.querySelectorAll('.select-custom__input');

    for(let i = 0; i < field.length; i++){
        var decreaseNum = function(){

            if(parseInt(input[i].value) <= 0) {
                input[i].value === 0;
            } else {
                input[i].value = parseInt(input[i].value) - 1;
            }
        }
        var encreaseNum = function(){
            input[i].value = parseInt(input[i].value) + 1;
        }
        btnPlus[i].addEventListener('click', encreaseNum)
        btnMinus[i].addEventListener('click',decreaseNum)

    }
}
setNumbersOfCars();

let filterToggle = document.querySelector('.map-filter__toggle');
let filter = document.querySelector('.map-filter__form')
let filterClose = document.querySelector('.map-filter__close')
if(filterToggle){
    filterToggle.addEventListener('click', function(e){
        e.preventDefault();
        if(!filter.classList.contains('active')){
            filter.classList.add('active')
        }
    })
}
if(filterClose){
    filterClose.addEventListener('click', function(e){
        e.preventDefault()
        filter.classList.remove('active');
    })
}

let search = document.querySelector('.header-search'),
    searchPanel = document.querySelector('.header__searchbar'),
    searchPanelClose = document.querySelector('.header__searchbar-close');


search.addEventListener('click', function(e){
    e.preventDefault;
    searchPanel.classList.toggle('active');

})
searchPanelClose.addEventListener('click', function(){
    searchPanel.classList.remove('active');
})
